<template>
  <div>
    <portal to="header">
      <terminal-header
          :title="$t('Fast Receiving')"
          @barcode="handleBarcode"
      />
    </portal>

    <portal to="settings">
      <tiles :schema="tilesSchema"/>
    </portal>

    <div v-if="action === 'scanAcceptance'">
      <div class="q-pa-sm border-bottom row items-center text-grey-7 text-weight-bold q-mb-xs">
        <h6 class="q-ma-none text-subtitle2 text-weight-bold">
          {{ $t('Receiving') }}
        </h6>

        <q-badge
            color="info"
            class="q-py-xs q-mx-sm"
            style="cursor:pointer"
        >
        <span class="q-mr-sm">
          {{ $t('new') }}
        </span>

          <q-spinner
              v-if="isLoadingNew"
              color="light-blue-9"
              size="1rem"
          />

          <span v-else>
          {{ newItems }}
        </span>
        </q-badge>

      </div>

      <div v-if="items.length === 0 && !$service.acceptance.isLoading" class="q-pa-md q-gutter-sm">
        <q-banner inline-actions rounded class="bg-orange text-white">
          {{ $t('There was no purchase orders found') }}

          <!--        <template v-slot:action>-->
          <!--          <q-btn flat label="Turn ON Wifi" />-->
          <!--          <q-btn flat label="Dismiss" />-->
          <!--        </template>-->
        </q-banner>
      </div>

      <q-table
          :style="`height: ${terminalContainerHeight - 50}px;`"
          row-key="id"
          :rows="items"
          :columns="columns"
          v-model:pagination="pagination"
          :loading="$service.acceptance.isLoading"
          :filter="filter"
          :rows-per-page-options="[]"
          table-header-class="d-none"
          virtual-scroll
          binary-state-sort
          flat
          @request="onRequest"
      >
        <template v-slot:loading>
          <q-inner-loading
              showing
              color="primary"
          />
        </template>

        <template v-slot:body="props">
          <q-tr
              :props="props"
              class="clickable"
              @dblclick="onRowDblClick(props.row)"
          >
            <q-td
                key="details"
                :props="props"
                style="max-width: 100vw; padding: 0 6px 6px !important;"
                class="no-border"
            >
              <div class="border border--radius-sm rounded q-py-xs q-px-sm">
                <div class="row items-center q-py-xs q-mb-xs">
                  <q-badge
                      :color="stateColors[props.row.state]"
                      :label="$t(props.row.state)"
                      class="q-py-xs q-mr-sm text-capitalize"
                  />

                  <q-space/>

                  <div v-if="props.row.extDate">
                    {{ $moment(props.row.extDate.date).format($appOptions.formats.date) }}
                  </div>
                </div>

                <div
                    v-if="props.row._embedded && props.row._embedded.warehouse"
                    class="text-h6 text-center text-capitalize text-weight-bold"
                    style="white-space: normal !important;"
                >
                  {{ props.row._embedded.warehouse.name }}
                </div>

                <div class="text-caption text-weight-bold text-center">
                  {{ $t('ID') + ': ' + props.row.id }}
                </div>

                <div v-if="props.row.extId" class="text-caption text-weight-bold text-center">
                  {{ $t('Ext. ID') + ': ' + props.row.extId }}
                </div>

                <div v-if="props.row.comment" class="text-caption text-center"
                     style="white-space: normal !important; max-width: calc(100vw - 20px);">
                  {{ props.row.comment }}
                </div>
              </div>
            </q-td>
          </q-tr>
        </template>
      </q-table>
<!--      <assistant-object :data="assistantObject"/>-->
    </div>

    <div v-if="action === 'next'">

      <assistant-object :data="assistantObjectNext"/>

    </div>
  </div>
</template>

<script>


// Vuex
import {mapActions, mapGetters, mapMutations} from 'vuex'

export default {
  name: 'FastReceiving',
  components: {
  },
  data () {
    return {
      filter: '',
      pagination: {
        page: 1,
        rowsNumber: 5,
      },
      itemsFound : [],
      updatedItems: {},
      columns: [
        {
          label: '',
          name: 'details',
          align: 'left'
        }
      ],
      stateColors: {
        new: 'info',
        confirmed: 'yellow',
        complete: 'success',
        checking: 'warning',
        deleted: 'danger',
        preset: 'warning',
        archived: 'dark'
      },
      typeLabels: {
        products: 'Products',
        items: 'Items',
        palletized: 'Palletized',
        batch: 'Batch & EXP'
      },
      typeColors: {
        products: 'blue-3',
        items: 'deep-purple-3',
        batch: 'green-2',
        palletized: 'teal-11'
      },
      helper: null,
      items: [],
      newItems: 0,
      isLoadingNew: false,
      action: 'scanAcceptance',
      acceptance: null,
      assistantObject: {
        title: 'Please scan',
        caption: 'Purchase order',
        captionColor: 'amber',
        image: 'scanOrder'
      },
      assistantObjectNext: {
        title: 'Please scan',
        caption: 'Item',
        captionColor: 'amber',
        image: 'scanOrder'
      }
    }
  },
  computed: {
    ...mapGetters([
      'terminalContainerHeight'
    ]),
    tilesSchema () {
      const options = []

      if (this.acceptance ) {
        options.push({
          id: 1,
          label: this.$t('Back'),
          icon: 'refresh',
          value: true,
          onChanged: () => {
            if (this.action === 'next') {
              this.action = 'scanAcceptance'
              this.acceptance = null
            }
          }
        })
      }


      return options
    }
  },
  mounted () {
    const data = {
      message: 'Please select the Purchase order or create a new one!',
      disabledNotifications: true
    }
    this.addHelper(data)
        .then(helper => {
          this.helper = helper
        })

        this.onRequest({pagination: {page: 1, per_page: 5}})
            .then(({ totalItems }) => {
              this.newItems = totalItems
            })
            .catch(() => {
            })

  },
  unmounted () {
    if (this.helper) {
      this.removeHelper(this.helper.id)
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification',
      'deleteAllHelpers',
      'removeHelper',
      'addNotification'
    ]),
    ...mapActions([
      'addHelper'
    ]),
    onRequest (data = {}) {
      this.pagination = data.pagination || {}

      const query = this.$utils.buildQuery(this.pagination)

      query['order-by'] = [
        {
          type: 'field',
          field: 'created',
          direction: 'desc'
        }
      ]
      query.filter = [
        { type: 'eq', field: 'state', value: 'new' }
      ]

      return this.$service.acceptance.getAll(query)
          .then(({ items, page, totalItems}) => {
            this.pagination = {
              ...this.pagination,
              page: page,
              rowsNumber: totalItems
            }

            this.items = items
            return { page, totalItems, items }
          })
    },
    handleReset () {
      this.action = 'scanBasket'
      this.acceptance = null
      this.barcode = null

      if (this.place) {
        this.$channels.user.publish('closePlace', this.place)
      }

      this.deleteAllHelpers()
      this.place = null
    },
    handleBarcode (barcode) {
      const query = {
        per_page: 5,
        page: 1,
        filter: [
          { type: 'eq', field: 'sku', value: barcode.raw },
          { type: 'eq', field: 'document', value: this.acceptance.id },
        ]
      }

      return this.$service.acceptanceItem.getAll(query, this.acceptance.state)
          .then(({items}) => {
            if(items && items.length > 0) {
              let item = items[0]
              item.isUpdated = true
              item.count = item.quantityExpected + 1
              this.itemsFound.push(item)
              this.save(this.acceptance)
            } else {
              this.handleNewItem(barcode.raw)
            }
          })

    },
    save (acceptance) {
      const items = this.getUpdatedItems().map(x => {
        return this.createItem(x, acceptance)
      })

      if (items.length <= 0) {
        return Promise.resolve({ items: [] })
      }

      return this.$service.acceptanceItem.saveAll(items, acceptance.state)
        .then(() => {
          this.updatedItems = []
          this.itemsFound = []
            this.addNotification('You have successfully updated purchase order items!')
        })
        .catch(err => {
          const data = {
            noCancel: true,
            okColor: 'negative',
            description: err.message
          }

          return this.$refs.confirmModal.show(data)
            .then(() => {
              return true
            })
        })
    },
    handleNewItem (barcodeRaw) {
      let barcode = barcodeRaw

      let offer = {
        id: null,
        article: null,
        type: 'simple',
        barcodes: [barcode],
        sku: barcode,
        dimensions: {},
        weight: 0,
        volume: 0,
        price: null,
        purchasingPrice: null,
        _embedded: {
          shop: null
        },
      }

      const product = {
        document: this.acceptance.id,
        sku: barcode,
        count: 1,
        _embedded: {
          productOffer: null,
          shop: offer._embedded?.shop
        }
      }

      this.items.unshift(product)
      this.$emit('items-change', this.items)
      this.pagination.rowsNumber = this.items.length

      return this.$service.acceptanceItem.save(product, null, this.acceptance.state)
        .then(acceptanceItem => {
          this.updatedItems = []
          this.itemsFound = []
          this.addNotification('You have successfully added new purchase order item.')
        })
    },
    getUpdatedItems () {
      if (this.acceptance && this.acceptance.id) {
        this.updatedItems[this.pagination.page] = this.itemsFound.filter(x => x.isUpdated)
        return Object.values(this.updatedItems).reduce((acc, items) => ([...acc, ...items]), [])
      }

      return this.itemsFound
    },
    createItem (row, acceptance) {
      let shop = row._embedded?.shop
        ? row._embedded.shop.id
        : row.shop

      if (!shop) {
        shop = row._embedded.productOffer?._embedded?.shop
        if (!shop) {
          shop = row._embedded.productOffer?._embedded?.virtualShop
        }

        if (shop) {
          if (shop.id) {
            shop = shop.id
          } else {
            shop = shop._links.self.href.split('/').pop()
          }
        }
      }

      if (row.id) {
        let item = {
          id: row.id,
          comment: row.comment
            ? row.comment
            : undefined
        }

        if (row._embedded?.productOffer?.id) {
          item.productOffer = {
            id: row._embedded.productOffer.id,
            shop: shop
          }
        }


        item = {
          ...item,
          price: row.price,
          purchasingPrice: row.purchasingPrice,
          count: row.count,
          sku: row.sku
            ? `${row.sku}`.trim()
            : undefined,
          trackingNumber: row.trackingNumber,
          batch: row.batch,
          state: row.state,
          status: row.status,
          expires: row.expires,
        }
        return item
      }

      const item = {
        document: acceptance.id,
        price: row.price,
        purchasingPrice: row.purchasingPrice,
        count: row.count,
        batch: row.batch
          ? row.batch
          : undefined,
        expires: row.expires
          ? row.expires
          : undefined,
        status: row.status,
        state: row.state,
        sku: row.sku
          ? `${row.sku}`.trim()
          : undefined,
        trackingNumber: row.trackingNumber,
        comment: row.comment
          ? row.comment
          : undefined
      }

      if (shop) {
        item.shop = shop
      }

      if (row._embedded?.productOffer?.id) {
        item.productOffer = {
          id: row._embedded.productOffer.id,
          shop: shop
        }
      }

      if (acceptance.state === 'checking') {
        item.expected = 0
      }

      return item
    },
    onRowDblClick (row) {
      this.acceptance = row
      this.action = 'next'
    },
  }
}
</script>
